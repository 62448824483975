import barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";
import Alpine from "alpinejs";
import $ from "jquery";
import hcOffcanvasNav from 'hc-offcanvas-nav';
import Swiper, { Navigation } from "swiper";
import { gsap } from "gsap";

// import Swiper and modules styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

window.Alpine = Alpine;
Alpine.start();

barba.use(barbaPrefetch);

let Nav;

function initSwiper() {
    window.swipernav = new Swiper(".swiper", {
        modules: [Navigation],
        loop: false,
        slidesPerView: "auto",
        navigation: {
            prevEl: ".swiper-button-prev",
            nextEl: ".swiper-button-next",
            hiddenClass: "!hidden",
            disabledClass: "!hidden",
        },
    });
}

function initMobileNav() {
    Nav = new hcOffcanvasNav('#main-nav', {
        customToggle: '.toggle',
        levelTitles: true,
        levelTitleAsBack: true,
        position : 'right',
        pushContent: '#content',
        levelSpacing: 100,
        width: 380
    });
}

function initHomePageNavBGColour() {
    //test if homepage only in url
    // const stickyHeight = 100;

    // document.addEventListener('scroll', (e) => {
    //   if (window.pageYOffset > stickyHeight) {
    //     nav.classList.add('mini')
    //   } else {
    //     nav.classList.remove('mini')
    //   }
    // })
}

//when domcontentloaded
document.addEventListener("DOMContentLoaded", () => {
    initSwiper()
    initMobileNav()
    initHomePageNavBGColour()
});


barba.hooks.beforeLeave((data) => {
    window.scrollTo(0, 0);

    // if(swiper) {
    //   swiper.destroy(false, false)
    // }
});

barba.hooks.afterEnter(data => {
    initMobileNav()
})

barba.init({
    debug: true,
    views: [
        {
            namespace: "home",
            beforeLeave(data) {
                console.log("home - beforeLeave running scripts");
            },
            beforeEnter(data) {
                console.log("home - afterEnter running scripts");

                $(window).scroll(() => {
                    if($('[data-barba-namespace="home"]').length){
                        var scrollDistance = $(window).scrollTop();

                        (scrollDistance > 80) ? toggleHeader(true) : toggleHeader(false);
                    }
                });
            },
        },
        {
            namespace: "parent",
            beforeLeave(data) {
                console.log("parent - beforeLeave running scripts");
            },
            beforeEnter(data) {
                console.log("parent - afterEnter running scripts");

                //reinit swiper here
                initSwiper()
            },
        },
        {
            namespace: "child",
            beforeLeave(data) {
                console.log("child - beforeLeave running scripts");
            },
            beforeEnter(data) {
                console.log("child - afterEnter running scripts");

                resetHeaderStyle();

                //reinit swiper here
                initSwiper()
            },
        },
    ],
    transitions: [
        {
            name: "home-parent",
            leave: function (data) {
                var done = this.async();
                const tl = gsap.timeline({duration: .5, onComplete: done})
                // console.log("home-parent leave transition", data);
                gsap.to(data.current.container, {
                    opacity: 0,
                });
            },
            enter: function (data) {
                var done = this.async(); //very important
                const tl = gsap.timeline();
                // console.log("home-parent enter transition", data);
                tl.from(data.next.container, {
                    opacity: 0,
                    duration: .5
                })
                    .from('.nav-link', {opacity:0, y:10, stagger: .1},'<')
                    .from('.main-content > *', {opacity:0, y:10, stagger: .1},'<')
                    .from('.main-content-2 > *', {opacity:0, scale:0, stagger: .1},"<")
                    .from('.logo-dots > *', {opacity:0, scale:0, stagger: .1},"<")
                    .fromTo('.active', {opacity:0},{opacity:1,duration:0.2, repeat:1})

                done(); //very important

            },
            // from: {
            //   namespace: 'home'
            // },
            // to: {
            //   namespace: ['parent', 'child']
            // }
        }
    ],
});

function toggleHeader(status){
    // Set toggle elements
    let $nav = $('#desktop-navigation');
    let $downloadBtn = $nav.find('[download]');
    let $hamburgerBtn = $nav.find('a[aria-label="Open Menu"]');
    let $divider = $downloadBtn.parent().next();
    let $logoWhite = $nav.find('img.logo-white');
    let $logoColour = $nav.find('img.logo-colour');

    if(status){
        // Toggle header background colour
        $nav.removeClass('bg-transparent').addClass('bg-white');

        // Toggle header download button colour
        $downloadBtn.removeClass('text-white').addClass('text-black');

        // Toggle divider colour
        $divider.removeClass('text-white').addClass('text-black');

        // Toggle header hamburger button colour
        $hamburgerBtn.removeClass('text-white').addClass('text-black');

        // Toggle header logo
        $logoWhite.hide();
        $logoColour.show();
    } else {
        // Toggle header background colour
       $nav.removeClass('bg-white').addClass('bg-transparent');

        // Toggle header download button colour
        $downloadBtn.removeClass('text-black').addClass('text-white');

        // Toggle divider colour
        $divider.removeClass('text-black').addClass('text-white');

        // Toggle header hamburger button colour
        $hamburgerBtn.removeClass('text-black').addClass('text-white');

        // Toggle header logo
        $logoColour.hide();
        $logoWhite.show();
    }
}